@import '../../../utils/variables.scss';

.eventInfoBlock {
  font-family: $fontGeneral;
  font-size: $font-text-size;
  text-align: center;
  color: $color-black;
  margin-block: 15px;
  &__soloist  {
    margin-block-start: 10px;
  }
  @media (max-width: 768px) {
    font-size: $font-text-size_mobile;
    margin-block: 10px;
  }
}