@import url('https://fonts.googleapis.com/css2?family=Artifika&family=Podkova:wght@400..800&display=swap');

@import './fonts.scss';



$color-light: #EFEAEA;
$color-dark: #666;
$color-black: #000;
$color-white: #fff;

$font-main-size: 75px;
$font-section-size: 35px;
$font-section-size_tablet: 30px;
$font-section-size_mobile: 25px;
$font-subtitle-size: 25px;
$font-subtitle-size_mobile: 20px;
$font-text-size: 18px;
$font-text-size_tablet: 16px;
$font-text-size_mobile: 14px;
$font-small-size: 14px;
$font-small-size_mobile: 16px;

$fontGeneral: 'Times New Roman', Times, serif;
$fontSecondary: Arial, Helvetica, sans-serif;