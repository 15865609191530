@import url('https://fonts.googleapis.com/css2?family=Artifika&family=Podkova:wght@400..800&display=swap');


.podkova-regular {
  font-family: "Podkova", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.podkova-medium {
  font-family: "Podkova", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.artifika-regular {
  font-family: "Artifika", serif;
  font-weight: 400;
  font-style: normal;
}