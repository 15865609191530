@import '../../utils/variables.scss';

.footer {
  background-color: $color-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-block-end: 20px;

  &__copyright {
    color: $color-black;
    font-family: $fontSecondary;
    font-size: $font-small-size;
    text-shadow: 1px 1px 0px $color-light;
  }
 
  @media (max-width: 767px) {
    padding: 25px 25px;
  }
}