@import '../../../../utils/variables.scss';

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 20px 30px;
  
  &__header {
    opacity: 0;
    font-family: $fontGeneral;
    font-weight: bold;
    font-size: $font-section-size;
    animation: fadeIn 5s forwards;
  }
  &__button {
    opacity: 0;
    animation: fadeIn 5s forwards;
    animation-delay: 3s;
  }
  &__button:nth-child(1) {
    animation-delay: 0.5s;
  }
  
  &__button:nth-child(2) {
    animation-delay: 1s;
  }
  
  &__button:nth-child(3) {
    animation-delay: 1.5s;
  }
  
  &__button:nth-child(4) {
    animation-delay: 2s;
  }
  
  &__button:nth-child(5) {
    animation-delay: 2.5s;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.navbar {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border: none;
  border-radius: 0;
  padding: 18px 25px;
  background-color: $color-light;
  max-inline-size: 100vw;
  @media (max-width: 768px) {
      display: none;
  }
  &__header {
    display: none;
  }

}