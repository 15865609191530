
@import '../../../utils//variables.scss';

.button {
  position: relative;
  z-index: 10;
  border-radius: 10px;
  padding-block: 5px;
  overflow: hidden;
  font-family: $fontSecondary;
  font-size: $font-small-size;
  background-color: transparent;
  inline-size: 120px;
  max-block-size: 30px;
  transition: transform .3s ease-in-out;
  box-shadow: 0 2px 10px 0 #0000006d;
  border: 1px solid #000;

  &:focus {
    outline: none;
  }
  &:focus-visible {
    border: 2px solid #000;
    transform: scale(1.1) 
  }
  &:hover {
    transform: scale(1.1)
  }
} 

