@import '../../utils/variables.scss';

.navbar {
  position: relative;
  max-inline-size: 724px;
  &_mobile {
    position: relative;
    display: block;
      width: 25px;
      height: 3px;
      background-color: #000;
  }
    &_mobile::before {
      content: '';
      position: absolute;
      top: -10px;
      width: 100%;
      height: 3px;
      background-color: #000;
    }
    &_mobile::after {
      content: '';
      position: absolute;
      top: 10px;
      width: 100%;
      height: 3px;
      background-color: #000;
    }
    &_mobile_active,
    &_mobile_active::before,
    &_mobile_active::after {
      transform: rotate(90deg);
    }
    &__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      &_mobile {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; /* Начальное состояние — вне экрана */
        z-index: 10000;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px; /* Разрыв между кнопками */
        background-color: $color-light;
        // transform: translateX(-100%);
        // transition: transform .3s ease-in-out;  
        visibility: hidden;
        opacity: 0;      
        &_opened {
          visibility: visible;
          // transform: translateX(0);   
          display: flex;
          opacity: 1;
          transition: visibility .3s ease-in-out, opacity .3s ease-in-out;


        }
        &_closed {
          // transform: translateX(-100%);        
          visibility: hidden;
          opacity: 0;
          transition: visibility .3s ease-in-out, opacity .3s ease-in-out;
        }
      }
    }
  }


