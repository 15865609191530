@import '../../utils/variables.scss';


.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 18px 25px;
  gap: 15px;
  background-color: $color-light;
  &__title {
    font-family: $fontGeneral;
    font-size: $font-text-size;
    color: $color-black;
  }
  &_mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}
