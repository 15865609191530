.slider {
  display: flex;
  align-items: center;
  block-size: 450px;
  // max-block-size: 450px;
  inline-size: 100%;
  gap: 10px;

  &_modal {
    block-size: 100%;
    max-block-size: 100%;
    inline-size: 100%;
  }
  @media (max-width: 768px) {
    max-block-size: 300px;
  }
}