.slider {
  margin: 0 auto;
  position: relative;
  inline-size: 100%;
  min-inline-size: 200px;
  block-size: 100%;
  max-block-size: 450px;
  overflow: hidden;

  &_modal {
    inline-size: 80vw;
    max-inline-size: 100%;
    block-size: 80vh;
    max-block-size: 100%;
    cursor: none;
  }
}