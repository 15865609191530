@import '../../../utils/variables.scss';

.mediaVideo {
  display: flex;
  flex-direction: column;
  gap: 10px;  
  padding: 20px 30px;
  align-items: center;
  justify-content: center;
  background-color: $color-light;
  border: 1px solid #000;
  border-radius: 10px;
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    inline-size: 100%;
    margin-block-start: 20px;
  }
}